<template>
  <v-card class="dates-help pa-3" @click.stop="">
    <v-card-title class="headline">Why set dates?</v-card-title>

    <v-card-text>
      <h3 class="title my-3">1. Notify users with labels</h3>
      <p>
        The labels 'new' and 'almost removed' on the climbs in list and in the map are based on these dates. The number
        of days these labels persist can be set your gym settings in the admin tabs. Your current settings are:
      </p>
      <v-list two-line subheader>
        <v-list-item>
          <v-list-item-avatar><v-icon class="grey lighten-1">tl-new-box</v-icon></v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>New label</v-list-item-title>
            <v-list-item-subtitle>Days to mark climb as new</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <span>{{ gym.label_new_days }}</span>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar><v-icon class="grey lighten-1">tl-new-releases</v-icon></v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Almost removed label</v-list-item-title>
            <v-list-item-subtitle>Days to mark climb as almost removed</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <span>{{ gym.label_removed_days }}</span>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <h3 class="title my-3">2. Old and new section</h3>
      <p>The updates section on the dashboard is based on the supplied dates as follows:</p>
      <dl class="ml-3">
        <dt class="font-weight-medium">Group renewal dates</dt>
        <dd class="mb-3">
          If more than 33% of it's climbs are set/removed on a certain date, that date is used for group.
        </dd>
        <dt class="font-weight-medium">Wall renewal dates</dt>
        <dd class="mb-3">
          If more than 50% of it's climbs are set/removed on a certain date, that date is used for wall.
        </dd>
      </dl>

      <h3 class="title my-3">Remark</h3>
      <v-alert :value="true" type="warning">
        <span>Setting '<i>Expected date removed</i>' doesn't automatically remove the climb on that date.</span>
      </v-alert>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="close">Got it!</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState(['gym']),
  },
  methods: {
    ...mapActions('dialog', ['close']),
  },
}
</script>
